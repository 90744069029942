import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Fonction lazyRetry pour gérer le chargement asynchrone des composants avec réessai
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
      const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
      );

      componentImport().then((component) => {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
          resolve(component);
      }).catch((error) => {
          if (!hasRefreshed) {
              window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
              return window.location.reload();
          }
          reject(error);
      });
  });
};

const Home = lazy(() => lazyRetry(() => import('./components/Home/Home')));
const Login = lazy(() => lazyRetry(() => import('./components/Login/Login')));
const NotFound = lazy(() => lazyRetry(() => import('./components/Error/404')));
const Logout = lazy(() => lazyRetry(() => import('./components/Profil/Logout')));
const Forgot = lazy(() => lazyRetry(() => import('./components/Forgot/Forgot')));
const Contact = lazy(() => lazyRetry(() => import('./components/Contact/Contact')));
const Exchange = lazy(() => lazyRetry(() => import('./components/Exchange/Exchange')));
const Register = lazy(() => lazyRetry(() => import('./components/Register/Register')));
const Caution = lazy(() => lazyRetry(() => import('./components/LegalDocuments/Caution')));
const Wallet = lazy(() => lazyRetry(() => import('./components/Profil/Dashboard/Wallet')));
const History = lazy(() => lazyRetry(() => import('./components/Profil/Dashboard/History')));
const Dashboard = lazy(() => lazyRetry(() => import('./components/Profil/Dashboard/Dashboard')));
const Subscription = lazy(() => lazyRetry(() => import('./components/Subscription/Subscription')));
const LegalDocuments = lazy(() => lazyRetry(() => import('./components/LegalDocuments/Documents')));
const SubscriptionSuccess = lazy(() => lazyRetry(() => import('./components/Subscription/Success')));
const TradingView = lazy(() => lazyRetry(() => import('./components/Profil/Dashboard/TradingView')));
const PrivacyPolicy = lazy(() => lazyRetry(() => import('./components/LegalDocuments/PrivacyPolicy')));
const Informations = lazy(() => lazyRetry(() => import('./components/Profil/Informations/Informations')));
const Accompaniement = lazy(() => lazyRetry(() => import('./components/Profil/Accompaniement/Accompaniement')));
const TermsOfUse = lazy(() => lazyRetry(() => import('./components/LegalDocuments/TermsOfUse')));
const CGV = lazy(() => lazyRetry(() => import('./components/LegalDocuments/CGV')));

const ModalCookies = lazy(() => lazyRetry(() => import('./components/Layouts/ModalCookies')));

const Fundamentals = lazy(() => lazyRetry(() => import('./components/Academy/Fundamentals')));
const Learning = lazy(() => lazyRetry(() => import('./components/Academy/Learning')));
const Analysis = lazy(() => lazyRetry(() => import('./components/Academy/Analysis')));
const ContentCourse = lazy(() => lazyRetry(() => import('./components/Academy/ContentCourse')));

const Sitemap = lazy(() => lazyRetry(() => import('./components/Sitemap')));

class RouterApp extends React.Component {
  render() {
    return (
        <Router>
          <ModalCookies />
          <Suspense fallback={
            <div className="loading">
              <div className="loader"></div>
              <div>Chargement...</div>
            </div>
          }>
            <Routes>
              {/* NO AUTHENTICATION REQUIRED START */}
              <Route path="/" element={<Home showNavbar={true} />} />
              <Route path="/sitemap.xml" element={<Sitemap showNavbar={true} />} />
              <Route path="/legal-documents/caution" element={<Caution showNavbar={true} />} />
              <Route path="/legal-documents/privacy-policy" element={<PrivacyPolicy showNavbar={true} />} />
              <Route path="/legal-documents" element={<LegalDocuments showNavbar={true} />} />
              <Route path="/legal-documents/terms-of-use" element={<TermsOfUse showNavbar={true} />} />
              <Route path="/legal-documents/cgv" element={<CGV showNavbar={true} />} />
              <Route path="/login" element={<Login showNavbar={false} />} /> 
              <Route path="/register" element={<Register showNavbar={false} />} />
              <Route path="/register/confirmation" element={<Register showNavbar={false} confirmation={true} />} />
              <Route path="/forgot/:key?" element={<Forgot showNavbar={false} />} />
              <Route path="/contact" element={<Contact showNavbar={true} />} />

              <Route path="/academy/fundamentals" element={<Fundamentals showNavbar={true} />} />
              <Route path="/academy/learning" element={<Learning showNavbar={true} />} />
              <Route path="/academy/analysis" element={<Analysis showNavbar={true} />} />
              <Route path="/academy/course/:courseToken" element={<ContentCourse showNavbar={true} />} />
              {/* NO AUTHENTICATION REQUIRED END */}

              {/* AUTHENTICATION REQUIRED START */}
              <Route path="/my-dashboard" element={<Dashboard showNavbar={true} />} />
              <Route path="/my-dashboard/history" element={<History showNavbar={true} />} />
              <Route path="/my-dashboard/wallet" element={<Wallet showNavbar={true} />} />
              <Route path="/my-dashboard/trading-view" element={<TradingView showNavbar={true} />} />
              <Route path="/my-account" element={<Informations showNavbar={true} />} />
              <Route path="/exchange" element={<Exchange showNavbar={true} />} />
              <Route path="/subscription" element={<Subscription showNavbar={false} />} />
              <Route path="/subscription/success" element={<SubscriptionSuccess showNavbar={false} />} />
              <Route path="/accompaniement" element={<Accompaniement showNavbar={true} />} />
              <Route path="/logout" element={<Logout />} />
              {/* AUTHENTICATION REQUIRED END */}

              {/* ERROR PAGE START */}
              <Route path="*" element={<NotFound showNavbar={true} />} />
              {/* ERROR PAGE END */}
            </Routes>
          </Suspense>
        </Router>
    );
  }
}

export default RouterApp;